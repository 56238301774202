/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import {config} from '../../constants/Constants'
import Header from "../../views/Common/Header/Header";
import Footer from "../../views/Common/Footer/Footer";
import Alert from "../../shared/Alerts"
import Spinner from "../../shared/Spinner"
import axios from 'axios';
import Papa from 'papaparse'


export default class View extends React.Component{   
    constructor(){        
        super();
        this.handleChange.bind(this)
        this.state = {             
            literals : {},
            displayLang : '',
            literalsData : [],
            formData : {fname:'',lname:''},
            success : false,
            msg : '',
            spinner : false,
            params : {},
            rate: {}
            }
        }          
    
        componentDidMount() { 
            const params = new URLSearchParams(window.location.search)
            // const params = new URLSearchParams(window.location.hash.split('?')[1]);
            let obj =  {
                id : params.get('id'),
                // type : params.get('lot-type'),
                name : params.get('lot-name'),
                rate : params.get('rate'),
            }           
            let subject= {
                subject : params.get('lot-name')
            }
            this.setState({formData : subject, params : obj})
            let literal = JSON.parse(localStorage.getItem("literals"));                
            let selectLang = localStorage.getItem("displayLang") 
            if(literal !== null){
                this.setState({literals : literal});           
                this.setState({displayLang : selectLang});   
            }
            if(selectLang == null){ 
                selectLang = navigator.language || navigator.userLanguage; 
                selectLang = selectLang.split('-')[0]
                this.setState({displayLang : selectLang});              
            }
            Papa.parse("https://docs.google.com/spreadsheets/d/19MJNY_yiGz4qVTW5tGfqZJO82JFy9lrMIx6DLw7xQjE/pub?gid=1443882505&output=csv", {
                download: true,
                header: true,
                complete: (res) => {
                    let result = this.mapLanguages(selectLang, res.data);            
                    this.setState({
                        literals : result,
                        literalsData : res.data
                    })
                },
            });
            Papa.parse("https://docs.google.com/spreadsheets/d/19MJNY_yiGz4qVTW5tGfqZJO82JFy9lrMIx6DLw7xQjE/pub?gid=527077082&output=csv", {
                download: true,
                header: true,
                complete: (res) => {
                    let rate = res.data.filter(x=>x.Seq === this.state.params.id);
                    console.log(rate);
                    this.setState({
                        rate : rate[0],
                    })
                },
            });
            // axios.get("https://sheet.best/api/sheets/7b96c3ab-e6ec-4108-ae28-fe5810458456/tabs/Literals").then((res)=>{
            //     let result = this.mapLanguages(selectLang, res.data);            
            //     this.setState({
            //         literals : result,
            //         literalsData : res.data
            //     }) 
            // })
            // axios.get("https://sheet.best/api/sheets/7b96c3ab-e6ec-4108-ae28-fe5810458456/tabs/Lots").then((res)=>{
            //     let rate = res.data.filter(x=>x.Seq === this.state.params.id);
            //     console.log(rate);
            //     this.setState({
            //         rate : rate[0],
            //     })
            // })
        }
        mapLanguages(language, languageData){           
            let languageArray = {};
            if(language == 'fr'){            
                this.setState({displayLang : 'fr'});
                localStorage.setItem("displayLang", 'fr')
            }else if(language == 'en'){            
                this.setState({displayLang : 'en'});
                localStorage.setItem("displayLang", 'en')
            } 
            languageData.forEach((item)=>{                          
                languageArray[item.key] = item[language];            
            })
            localStorage.setItem('literals', JSON.stringify(languageArray))
            return languageArray;
        }
    
        selectedLanguage(e){         
            this.setState({displayLang : e});
            let result = this.mapLanguages(e,this.state.literalsData);
            this.setState({literals : result});
            window.location.reload();
        }

    handleSubmit(e){
        e.preventDefault();
        let form = this.state.formData;
        form['emailBody'] = this.state.literals.emailBody        
        form['kindRegards'] = this.state.literals.kindRegards        
        this.setState({spinner : true});
        axios.post(config.url.API_URL+'sendEmail', form)
            .then(result => {        
            this.setState({spinner : false});
                this.setState({
                    success : true,
                    msg : this.state.literals.emailBody
                })
            })
    }

    handleChange(event) {        
        let form = this.state.formData;
        form[event.target.name] = event.target.value;       
        this.setState({
            formData : form
        }, console.log(this.state.formData));       
    }

    render() {
              

    return(
            <>  
                <Header
                    displayLang = {this.state.displayLang}                    
                    literals = {this.state.literals}
                    
                    selectedLanguage = {(e) => {this.selectedLanguage(e)}}
                />
                <div className="inner-header back-img">                          
                </div>
                <div className="container contact-us">
                    <div className="row m-0 justify-content-center align-items-center" style={{width:'100%'}}>
                        <div className="col-md-6">
                            <div className="contact-body text-center">
                                <h2>{this.state.literals.haveQuestion}</h2>
                                <p>{this.state.literals.hereToHelp}</p>
                                <a href="#" className="btn btn-lg rounded-pill btn-green">{this.state.literals.askQuestion}</a>
                            </div>
                            <div className="card-deck" style={{marginTop: '20px'}}>
                                <div className="card shadow">
                                    <div className="card-body">
                                        <h4 className="text-green">
                                            {this.state.rate['Lot Name']}
                                        </h4>
                                        <h5 className="text-blue">
                                            {this.state.displayLang == 'en' ? 
                                                this.state.rate['Lot Type'] : 
                                                this.state.rate['Lot Type fr']
                                            }
                                        </h5>
                                    </div>
                                    <div class="card-footer">
                                            <p className="desc m-0" style={{fontSize: '13px', fontWeight: 'bold', fontWeight: 'bold'}}>
                                                {this.state.displayLang == 'en' ? 
                                                this.state.rate['Address en'] : this.state.rate['Address fr']
                                                }
                                            </p>
                                            <div className='row px-3' style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                <div className="col-7 p-0" style={{whiteSpace: 'pre-wrap'}}>
                                                    {this.state.displayLang == 'en' ? 
                                                        this.state.rate['Contact Desc en'] : this.state.rate['Contact Desc fr']
                                                    }
                                                </div>
                                                <div className="col-5 p-0" style={{whiteSpace: 'pre-wrap'}}>
                                                    {this.state.rate['Contact']}
                                                </div>
                                            </div>
                                            <div className="row px-3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.rate['Rate Desc en'] == "" ? "" : this.state.literals.taxInclude}
                                            </div>
                                            <div className='row px-3' style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                <div className="col-7  p-0" style={{whiteSpace: 'pre-wrap'}}>
                                                    {this.state.displayLang == 'en' ? 
                                                        this.state.rate['Rate Desc en'] : this.state.rate['Rate Desc fr']
                                                    }
                                                </div>
                                                <div className="col-5 p-0" style={{whiteSpace: 'pre-wrap'}}>
                                                    {this.state.rate['Rate Value']}
                                                </div>
                                            </div>
                                            <div className="row px-3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.rate['Sucscription Desc en'] == "" ? "" : this.state.literals.diffSubscription}
                                            </div>
                                            <div className='row px-3' style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                <div className="col-7 p-0" style={{whiteSpace: 'pre-wrap'}}>
                                                    {this.state.displayLang == 'en' ? 
                                                        this.state.rate['Sucscription Desc en'] : this.state.rate['Sucscription Desc fr']
                                                    }
                                                </div>
                                                <div className="col-5 p-0" style={{whiteSpace: 'pre-wrap'}}>
                                                    {this.state.rate['Sucscription Value']}
                                                </div>
                                            </div>
                                        
                                    </div>
                                </div>                                   
                            </div>
                            <div className="card-deck" style={{marginTop: '20px'}}>
                                <div className="card shadow">
                                    <div className="card-body">
                                        {/* <h4 className="text-green">
                                            {this.state.params.name}
                                        </h4> */}
                                        <h5 className="text-blue">
                                            {this.state.literals.monthlyPlans}
                                        </h5>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row">
                                            <div class="col-6">
                                                <span style={{whiteSpace: 'pre-wrap'}}>
                                                    {this.state.displayLang == 'en' ? 
                                                        this.state.rate['Monthly Plan en'] : 
                                                        this.state.rate['Monthly Plan fr']
                                                    }
                                                </span>  
                                            </div>
                                            <div class="col-6">
                                                <span style={{whiteSpace: 'pre-wrap'}}>
                                                    {this.state.rate['Monthly Plan Rate']}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>
                            <div className="contact-form">
                                <h3 className="text-center">{this.state.literals.contact}</h3>
                                <div className="mt-2">
                                    <Alert
                                        success = {this.state.success}
                                        msg = {this.state.msg}
                                    />
                                </div>  
                                <form onSubmit={(e)=>{this.handleSubmit(e)}}>
                                    <div className="form-group">
                                        <label for="exampleInputEmail1">{this.state.literals.fname}</label>
                                        <input type="text" className="form-control" name="fname" id="exampleInputEmail1"
                                            value = {this.state.formData.fname}
                                            onChange={(e)=>{this.handleChange(e)}}
                                            aria-describedby="emailHelp" placeholder={this.state.literals.fname}
                                            required/>
                                    </div>
                                    <div className="form-group">
                                        <label for="exampleInputEmail1">{this.state.literals.lname}</label>
                                        <input type="text" className="form-control" 
                                            name="lname"
                                            value = {this.state.formData.lname}
                                            onChange={(e)=>{this.handleChange(e)}}
                                            id="exampleInputEmail1" aria-describedby="emailHelp" 
                                            placeholder={this.state.literals.lname}
                                            required/>
                                    </div>
                                    <div className="form-group">
                                        <label for="exampleInputEmail1">{this.state.literals.email}</label>
                                        <input type="email" className="form-control" id="exampleInputEmail1" 
                                            name="email"
                                            value = {this.state.formData.email}
                                            onChange={(e)=>{this.handleChange(e)}}
                                            aria-describedby="emailHelp" placeholder={this.state.literals.email}
                                            required/>
                                    </div>
                                    <div className="form-group">
                                        <label for="exampleInputEmail1">{this.state.literals.phNo}</label>
                                        <input type="number" className="form-control" 
                                            name="ph_no"
                                            value = {this.state.formData.ph_no}
                                            onChange={(e)=>{this.handleChange(e)}}
                                            id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={this.state.literals.phNo}
                                            required/>
                                    </div>
                                    <div className="form-group">
                                        <label for="exampleFormControlTextarea1">{this.state.literals.yourQuestion}</label>
                                        <textarea className="form-control" 
                                            name="yourQuestion"
                                            value = {this.state.formData.question}
                                            onChange={(e)=>{this.handleChange(e)}}
                                            id="exampleFormControlTextarea1" rows="3" required></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-lg w-100 rounded-pill btn-primary">{this.state.literals.sendMsg}</button>
                                </form>
                            </div>   
                        </div>
                    </div>
                </div>
                <Spinner
                    spinner = {this.state.spinner}
                />
                <Footer
                    literals = {this.state.literals}
                />  
            </>
        )
    }
}
import React from "react";
import {Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import Footer from "../Common/Footer/Footer";
import Header from "../../views/Common/Header/Header";
import {config} from '../../constants/Constants'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import marker from '../../assets/images/marker.png';
import Parking from '../../assets/images/parking.png';
import axios from 'axios'
import Papa from 'papaparse'

export class Search extends React.Component{     
    constructor(props){
        super(props);
        this.state = {
            activeMarker: {},
            selectedPlace: {},
            showingInfoWindow: false,
            locations : [
                { title: 'Quebec'},
            ],
            literals : {},
            lots : [],
            displayLang : localStorage.getItem("displayLang"),
        }
    }

    componentDidUpdate(prevProp, prevState){
    }

    componentDidMount() { 
        let literal = JSON.parse(localStorage.getItem("literals"));                
        let selectLang = localStorage.getItem("displayLang");
        if(JSON.parse(localStorage.getItem("lots") !== null)){
            let lots = JSON.parse(localStorage.getItem("lots")); 
            this.setState({lots : lots});
        }
        if(literal !== null){
            this.setState({literals : literal});           
            this.setState({displayLang : selectLang});   
        }
        if(selectLang == null){ 
            selectLang = navigator.language || navigator.userLanguage; 
            selectLang = selectLang.split('-')[0]
            this.setState({displayLang : selectLang});              
        }
        Papa.parse("https://docs.google.com/spreadsheets/d/19MJNY_yiGz4qVTW5tGfqZJO82JFy9lrMIx6DLw7xQjE/pub?gid=1443882505&output=csv", {
            download: true,
            header: true,
            complete: (res) => {
                let result = this.mapLanguages(selectLang, res.data);            
                this.setState({
                    literals : result,
                    literalsData : res.data
                })
            },
        });
        Papa.parse("https://docs.google.com/spreadsheets/d/19MJNY_yiGz4qVTW5tGfqZJO82JFy9lrMIx6DLw7xQjE/pub?gid=527077082&output=csv", {
            download: true,
            header: true,
            complete: (res) => {
                this.setState({
                    lots : res.data,
                })
            },
        });
        // axios.get("https://sheet.best/api/sheets/7b96c3ab-e6ec-4108-ae28-fe5810458456/tabs/Lots").then((res)=>{
        //     this.setState({
        //         lots : res.data,
        //     }) 
        // })
        
    }
    mapLanguages(language, languageData){           
        let languageArray = {};
        if(language == 'fr'){            
            this.setState({displayLang : 'fr'});
            localStorage.setItem("displayLang", 'fr')
        }else if(language == 'en'){            
            this.setState({displayLang : 'en'});
            localStorage.setItem("displayLang", 'en')
        } 
        languageData.forEach((item)=>{                          
            languageArray[item.key] = item[language];            
        })
        localStorage.setItem('literals', JSON.stringify(languageArray))
        return languageArray;
    }

    selectedLanguage(e){         
        this.setState({displayLang : e});
        let result = this.mapLanguages(e,this.state.literalsData);
        this.setState({literals : result});
        window.location.reload();
    }

    more(id,item){
        let data = this.state.lots
        data.forEach(function (element) {
            element.Active = "false";
          });
        this.setState({ lots: data });
        data[id].Active = 'true'
        this.setState({ lots: data });
    }

    less(id,item){
        let data = this.state.lots
        data[id].Active = 'false'
        this.setState({ lots: data });
    }

    onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    });

    onInfoWindowClose = () =>
        this.setState({
        activeMarker: null,
        showingInfoWindow: false
        });
    onMapClicked = () => {
        if (this.state.showingInfoWindow)
            this.setState({
            activeMarker: null,
            showingInfoWindow: false
            });
        };
    
    contact(list){
        console.log(list);
        let rate;
        let type = list['Monthly Plan Rate'];
        if(this.state.displayLang == 'en'){
            rate = list['Monthly Plan en']
        }else{
            rate = list['Monthly Plan fr']
        }
        this.props.history.push('/contact-us?id='+list['Seq']);
        // this.props.history.push('/contact-us?lot-name='+list['Lot Name']+'&lot-type='+type+'&rate='+rate)
    }
    
    render() {        

    return(
            <> 
                <Header
                    displayLang = {this.state.displayLang}                    
                    literals = {this.state.literals}
                    
                    selectedLanguage = {(e) => {this.selectedLanguage(e)}}
                /> 
                <div className="main-container">
                    <div className="row">                            
                        <div className="col-md-6 col-lg-6 p-0" style={{ height:'100vh'}}>
                            {this.state.lots.length > 0 && <div className="map-frame">
                                <Map 
                                    google={this.props.google} 
                                    zoom={14}                       
                                    onClick={this.onMapClicked}                                    
                                    initialCenter={
                                        this.props.currentPosition
                                    }
                                    center= {this.props.currentPosition}
                                >   
                                    <InfoWindow
                                        marker={this.state.activeMarker}
                                        onClose={this.onInfoWindowClose}
                                        visible={this.state.showingInfoWindow}
                                        >
                                        <div>
                                            <h4>{this.state.selectedPlace.name}</h4>
                                        </div>
                                    </InfoWindow>
                                    {this.state.lots.map((x)=>{
                                        return(
                                            <Marker                                         
                                                onClick={this.onMarkerClick}
                                                icon={{url: marker, scaledSize: new this.props.google.maps.Size(40,40)}}
                                                position={{lat:x.Latitude, lng:x.Longitude}}
                                                label= {{ color: '#fff', fontWeight: 'bold', fontSize: '20px', text: x.Seq }}
                                            />
                                        )
                                    })}
                                </Map>
                            </div>}
                            </div>                                
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="locationBtn">
                                    <button type="button" onClick={this.props.getGeoLocation} className="btn rounded-pill btn-primary">
                                        {this.state.literals.useMyLocation}
                                    </button>
                                </div>
                                <div className="search-card">                                        
                                {this.state.lots.map((x,index)=>{ 
                                    let image = "https://drive.google.com/uc?export=view&id="+x.Image
                                    return(
                                    <Card>
                                        <CardContent>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-5 col-lg-5">
                                                    <img width='100%' src={image}/>
                                                </div>
                                                <div className="col-sm-12 col-md-7 col-lg-7">
                                                    <h4 className="text-green">{x['Lot Name']} ({x.Seq})</h4>
                                                    <h5 className="text-blue">
                                                        {this.state.displayLang == 'en' ? 
                                                            x['Lot Type'] : x['Lot Type fr']
                                                        }                                                            
                                                    </h5>
                                                    <span className="location"><b>{x['Location']}</b></span><br/>
                                                    <p className="desc m-0" style={{fontSize: '13px', fontWeight: 'bold', fontWeight: 'bold'}}>
                                                        {this.state.displayLang == 'en' ? 
                                                        x['Address en'] : x['Address fr']
                                                        }
                                                    </p>
                                                    <div className='row' style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                        <div className="col-7 p-0" style={{whiteSpace: 'pre-wrap'}}>
                                                            {this.state.displayLang == 'en' ? 
                                                                x['Contact Desc en'] : x['Contact Desc fr']
                                                            }
                                                        </div>
                                                        <div className="col-5 p-0" style={{whiteSpace: 'pre-wrap'}}>
                                                            {x['Contact']}
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                        {x['Rate Desc en'] == "" ? "" : this.state.literals.taxInclude}
                                                    </div>
                                                    <div className='row' style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                        <div className="col-7  p-0" style={{whiteSpace: 'pre-wrap'}}>
                                                            {this.state.displayLang == 'en' ? 
                                                                x['Rate Desc en'] : x['Rate Desc fr']
                                                            }
                                                        </div>
                                                        <div className="col-5 p-0" style={{whiteSpace: 'pre-wrap'}}>
                                                            {x['Rate Value']}
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                        {x['Sucscription Desc en'] == "" ? "" : this.state.literals.diffSubscription}
                                                    </div>
                                                    <div className='row' style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                        <div className="col-7 p-0" style={{whiteSpace: 'pre-wrap'}}>
                                                            {this.state.displayLang == 'en' ? 
                                                                x['Sucscription Desc en'] : x['Sucscription Desc fr']
                                                            }
                                                        </div>
                                                        <div className="col-5 p-0" style={{whiteSpace: 'pre-wrap'}}>
                                                            {x['Sucscription Value']}
                                                        </div>
                                                    </div>
                                                    <br/><button type="button" class="locBtn" onClick={(e) => {this.contact(x)}}>
                                                        {this.state.literals.moreInformation}                                                            
                                                    </button><br/>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                    )
                                })} 
                                </div>                                                                                                       
                            </div>
                    </div>                    
                </div>
                <div className="container quiest">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-5">
                            <h5 className="text-gray">{this.state.literals.whoIs}</h5>
                            <h1 className="text-green" style={{fontSize: "50px"}}>PARKÉO ?</h1>
                            <p>
                                {this.state.literals.para1}
                            </p>
                        </div>
                        <div className="col-md-7">
                            <img width="100%" style={{marginTop: "20px"}} src = {Parking}/>
                        </div>
                    </div>
                </div>
                <Footer
                    literals = {this.state.literals}                
                />
            </>
        )
    }
}

export default GoogleApiWrapper({
apiKey: config.url.googleKey
})(Search);
import React from "react";
import {Nav} from 'react-bootstrap';
import WhiteLogo from '../../../assets/images/white-logo.png';
import Paypal from '../../../assets/images/paypal.jpg';
import Secure from '../../../assets/images/secure.jpg';

export default function Footer(prop) {
    
    return(
        <> 
           <div className="main-container footer">
                <div className="container ">
                    <div className="row mb-5">
                        <div className="col-md-4">
                            <img width='60%' style={{marginBottom:'20px'}} src={WhiteLogo}/>
                            <p>
                                {prop.literals.para2}
                            </p>
                        </div> 
                        <div className="col-md-4">
                            <h6 className="text-white">
                                {prop.literals.quickLinks}
                            </h6>
                            <Nav defaultActiveKey="#" className="flex-column text-uppercase">                           
                                <Nav.Link href="/">
                                    {prop.literals.home}
                                </Nav.Link>
                                <Nav.Link href="/about-us">
                                    {prop.literals.about}
                                </Nav.Link>                           
                                <Nav.Link href="/services">
                                    {prop.literals.services}
                                </Nav.Link>
                            </Nav>
                        </div>
                        <div className="col-md-4">
                            <h6 className="text-white">
                                {prop.literals.paySecure}
                            </h6>
                            <img width='90px' style={{margin:'5px 5px'}} src={Paypal}/>
                            <img width='90px' style={{margin:'5px'}} src={Secure}/>
                        </div>
                    </div>
                </div>
                <div className="row copyright">
                    <div className="col-md-12">
                        <p>© 2021 Parkeo.ca, All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </>
    )
}
import React from 'react';
  ///---Shared component--///
import Spinner from "../../shared/Spinner"
  //---View--///
import View from '../../views/Search/Search'
export default class Search extends React.Component{
    constructor(props){        
        super(props);
        this.state = {             
            literals : JSON.parse(localStorage.getItem("literals")) || [],                        
            userLanguage : '',
            displayLang : 'en',
            currentPosition : {
                                lat: 46.81178987,
                                lng: -71.22386311
                            },
            lots: JSON.parse(localStorage.getItem("sheetData")) || [],  
            spinner : false          
        }
    }       

    componentDidMount() {                  
        var userLang = navigator.language || navigator.userLanguage; 
        userLang = userLang.split('-')[0]              
        this.setState({displayLang : userLang})                        
    }      

    selectedLanguage(e){ 
        this.setState({displayLang : e})
    }

    more(id,item){
        let data = this.state.lots
        data.forEach(function (element) {
            element.Active = "false";
          });
        this.setState({ lots: data });
        data[id].Active = 'true'
        this.setState({ lots: data });
    }

    less(id,item){
        let data = this.state.lots
        data[id].Active = 'false'
        this.setState({ lots: data });
    }

  ///---Get current location--///

    getGeoLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {                      
                    this.setState(prevState => ({
                        currentPosition: {
                            ...prevState.currentPosition,
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        }
                    }))                                                            
                }
            )
        }
    }
    

    render(){
        return(
            <div>
                <>                    
                    <View 
                        currentPosition = {this.state.currentPosition}                        
                        zoom = {this.state.zoom}
                        userLanguage = {this.state.userLanguage}
                        handleCityChange = {(e)=>{this.handleCityChange(e)}}
                        handleZoneChange = {(e)=>{this.handleZoneChange(e)}}
                        centerMoved={(mapProps, map)=>this.centerMoved(mapProps, map)}
                        more={(id, item)=>this.more(id, item)}
                        less={(id, item)=>this.less(id, item)}
                        getGeoLocation = {(e)=>{this.getGeoLocation(e)}}
                        history={this.props.history}
                    />
                    <Spinner 
                        spinner = {this.state.spinner}
                    />                     
                </>
            </div>
        )
    }
}
const prod = {
    url: {
        API_URL: 'https://www.parkeo.ca/parkue_backend/public/api/' ,
        googleSheet: "https://docs.google.com/spreadsheets/d/1r6EQlTfkyAxrrQFnvUv-g4E8bGvg9S5L2ttAEQDOqgg/edit?usp=sharing",
        googleKey : "AIzaSyDcPvfjCIqeUpGe8_IaI_-5rIZX9qtCUnI"
    }
};
const dev = {
    url: {
        API_URL: 'https://www.parkeo.ca/parkue_backend/public/api/',
        googleSheet: "https://docs.google.com/spreadsheets/d/1r6EQlTfkyAxrrQFnvUv-g4E8bGvg9S5L2ttAEQDOqgg/edit?usp=sharing",
        googleKey : "AIzaSyDcPvfjCIqeUpGe8_IaI_-5rIZX9qtCUnI"
    }
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
import React from 'react';
import { Route, Switch, Redirect  } from "react-router-dom";
import Home from './views/Home/Home';
import Result from './components/Search/Search';
import Services from './views/Services/Services';
import Contact from './components/Contact/Contact';
function Routes() {  

  return (
   
    <main>
        <Switch>
            <Route path={"/"} component={Result} exact/>
            <Route path={"/about-us"} component={Home}/>
            <Route path={"/services"} component={Services}/>
            <Route path="/contact-us" component={Contact}/>
            <Route path="/abonnementgroupemach" component={()=>{
              window.location.href = "https://processsystech.azurewebsites.net/Account/Login?id=4";
            }}/>
            <Route path="/11003" component={()=>{
              window.location.href = "https://blinkay.app/appz?z=43803";
            }}/>
            {/* Redirect route */}            
            <Redirect from="/" to="/" />
            
        </Switch>
    </main>
  );
}

export default Routes;

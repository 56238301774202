import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#1b3c93',
    },
})
)

export default function Spinner(prop) {  
  const classes = useStyles();  

  ///---Meterial Spinner--///

  return (
    <div>
      <Backdrop className={classes.backdrop} open={prop.spinner} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
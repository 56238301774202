import React from 'react';
  ///---Shared component--///
import Spinner from "../../shared/Spinner"
  //---View--///
import View from '../../views/Contact/Contact'

export default class Services extends React.Component{
    constructor(){        
        super();
        this.state = {             
            literals : JSON.parse(localStorage.getItem("literals")) || {},                        
            userLanguage : '',
            displayLang : 'en',                       
            spinner : false          
        }
    }      

    componentDidMount() {        
    }

    render(){
        return(
            <div>                            
                <>
                    <View 
                        currentPosition = {this.state.currentPosition}                        
                        zoom = {this.state.zoom}
                        sheetData = {this.state.sheetData}
                        userLanguage = {this.state.userLanguage}
                        displayLang = {this.state.displayLang}
                        literals = {this.state.literals}

                        handleCityChange = {(e)=>{this.handleCityChange(e)}}
                        handleZoneChange = {(e)=>{this.handleZoneChange(e)}}
                        centerMoved={(mapProps, map)=>this.centerMoved(mapProps, map)}
                        more={(id, item)=>this.more(id, item)}
                        less={(id, item)=>this.less(id, item)}
                        getGeoLocation = {(e)=>{this.getGeoLocation(e)}}
                    />
                    <Spinner 
                        spinner = {this.state.spinner}
                    />
                </>
            </div>
        )
    }
}
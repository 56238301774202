/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Parking from '../../assets/images/parkeo1.png'
import Header from "../../views/Common/Header/Header";
import Footer from "../../views/Common/Footer/Footer";
import axios from 'axios';
import Papa from "papaparse";

export default class View extends React.Component{     
    constructor(){        
        super();
        this.state = {             
            literals : {},
            displayLang : '',
            literalsData : []
        }
    }          

    componentDidMount() { 
        let literal = JSON.parse(localStorage.getItem("literals"));                
        let selectLang = localStorage.getItem("displayLang") 
        if(literal !== null){
            this.setState({literals : literal});           
            this.setState({displayLang : selectLang});   
        }
        if(selectLang == null){ 
            selectLang = navigator.language || navigator.userLanguage; 
            selectLang = selectLang.split('-')[0]
            this.setState({displayLang : selectLang});              
        }
        // axios.get("https://sheet.best/api/sheets/7b96c3ab-e6ec-4108-ae28-fe5810458456/tabs/Literals").then((res)=>{
        //     let result = this.mapLanguages(selectLang, res.data);            
        //     this.setState({
        //         literals : result,
        //         literalsData : res.data
        //     }) 
        // })
        Papa.parse("https://docs.google.com/spreadsheets/d/19MJNY_yiGz4qVTW5tGfqZJO82JFy9lrMIx6DLw7xQjE/pub?gid=1443882505&output=csv", {
            download: true,
            header: true,
            complete: (res) => {
                let result = this.mapLanguages(selectLang, res.data);            
                this.setState({
                    literals : result,
                    literalsData : res.data
                })
            },
        });
            
        }
    mapLanguages(language, languageData){           
        let languageArray = {};
        if(language == 'fr'){            
            this.setState({displayLang : 'fr'});
            localStorage.setItem("displayLang", 'fr')
        }else if(language == 'en'){            
            this.setState({displayLang : 'en'});
            localStorage.setItem("displayLang", 'en')
        } 
        languageData.forEach((item)=>{                          
            languageArray[item.key] = item[language];            
        })
        localStorage.setItem('literals', JSON.stringify(languageArray))
        return languageArray;
    }

    selectedLanguage(e){         
        this.setState({displayLang : e});
        let result = this.mapLanguages(e,this.state.literalsData);
        this.setState({literals : result});
        window.location.reload();
    }

    render() {
    return(
            <div>
            <>
                <Header
                    displayLang = {this.state.displayLang}                    
                    literals = {this.state.literals}
                    
                    selectedLanguage = {(e) => {this.selectedLanguage(e)}}
                />
                <div className="main-header back-img">          
                <h2>{this.state.literals.allAbout}</h2>
                <p>{this.state.literals.explore}</p>
                </div>
                <div className="container quiest">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6">
                            <h5 className="text-gray">{this.state.literals.about}</h5>
                            <h1 className="text-green" style={{fontSize: "45px"}}>
                                {this.state.literals.companyProfile}
                            </h1>
                            <p>
                                {this.state.literals.para2}
                            </p>
                        </div>
                        <div className="col-md-6">
                            <div className="img-parking">
                             <img width="100%" style={{marginTop: "20px"}} src = {Parking}/>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div className="why-parkeo">
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3>{this.state.literals.whoIs}</h3>
                                <h2>Parkéo ?</h2>
                            </div>
                            <div className="col-md-6">
                            <div className="content-why">                                
                                <ul>
                                    <li>{this.state.literals.li1}</li>
                                    <li>{this.state.literals.li2}</li>
                                    <li>{this.state.literals.li3}</li>
                                    <li>{this.state.literals.li4}</li>
                                </ul>
                            </div>
                            </div>
                            <div className="col-md-6">
                            <div className="content-why">                                
                                <ul>
                                    <li>{this.state.literals.li5}</li>
                                    <li>{this.state.literals.li6}</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer
                    literals = {this.state.literals}
                />
                </>
            </div>
        )
    }
}
import React, {useState, useEffect} from "react";
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import Logo from '../../../assets/images/LOGO PARKEO.png';
import axios from 'axios';
import Papa from 'papaparse';

export default function Header(props) {
    const [showFirst, setShowFirst] = useState(false);
    const [showLang, setShowLang] = useState(false);
    const [show, setShow] = useState(false);    
    const [literals, setLiterals] = useState([]);
    const [literalsData, setLiteralsData] = useState([]);
    const [lots, setLots] = useState([]);
    const [displayLang, setDisplayLang] = useState('');
        
  ///---Get literals from localstorage--///
    
    useEffect(() => {
        getLiterals();

      }, []);
    const getLiterals = () =>{
        let literal = JSON.parse(localStorage.getItem("literals"));        
        let lots = JSON.parse(localStorage.getItem("lots")); 
        let selectLang = localStorage.getItem("displayLang") 
        if(literal !== null && lots !== null){
            setLiterals(literal);
            setLots(lots);
            setDisplayLang(selectLang)            
        }
        if(selectLang == null){ 
            selectLang = navigator.language || navigator.userLanguage; 
            selectLang = selectLang.split('-')[0]
            setDisplayLang(selectLang);           
        }
        Papa.parse("https://docs.google.com/spreadsheets/d/19MJNY_yiGz4qVTW5tGfqZJO82JFy9lrMIx6DLw7xQjE/pub?gid=1443882505&output=csv", {
            download: true,
            header: true,
            complete: (res) => {
                let result = mapLanguages(selectLang, res.data);            
                setLiterals(result)
                setLiteralsData(res.data); 
            },
        });
        // axios.get("https://sheet.best/api/sheets/7b96c3ab-e6ec-4108-ae28-fe5810458456/tabs/Literals").then((res)=>{
        //     let result = mapLanguages(selectLang, res.data);            
        //     setLiterals(result)
        //     setLiteralsData(res.data); 
        // })
    }

    const mapLanguages = (language, languageData)=>{  
        let languageArray = {};
        if(language == 'fr'){            
            setDisplayLang('fr');
            localStorage.setItem("displayLang", 'fr')
        }else if(language == 'en'){            
            setDisplayLang('en');
            localStorage.setItem("displayLang", 'en')
        } 
        languageData.forEach((item)=>{                          
            languageArray[item.key] = item[language];            
        })
        localStorage.setItem('literals', JSON.stringify(languageArray))
        return languageArray;
    }

    return(
        <>
        <> 
           <div className="main-container header">
                <div className="container d-none d-lg-block">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6 text-white links">INFO@PARKEO.CA</div>
                        <div className="col-md-6 header-links">
                            <Navbar bg="gray" expand="lg">                        
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="ml-auto justify-content-end text-uppercase">
                                                                   
                                    </Nav>
                                </Navbar.Collapse>                            
                            </Navbar> 
                        </div>                        
                    </div>                      
                </div>                
            </div> 
            <div className="main-container p-2 d-none d-block d-sm-block d-md-block d-lg-none d-xl-none">            
                <div class="navbar navbar-inverse navbar-fixed-top" role="navigation">                    
                        <div class="navbar-header">
                            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse"> 
                                <i class="fa fa-bars"></i>
                            </button> 
                            <a class="navbar-brand" href="/"><img width='100px' src={Logo}/></a> 
                        </div>
                        <div class="navbar-collapse collapse text-uppercase">
                            <ul class="nav navbar-nav">
                                <li>
                                    <a href="https://processsystech.azurewebsites.net/Account/Register?language=fr&id=4" class="btn rounded-pill btn-red px-2 py-1 mt-1">
                                        {literals.newRates}
                                    </a>
                                </li>
                                <li>
                                    <a href="https://processsystech.azurewebsites.net/Account/Login?id=4" class="btn rounded-pill btn-primary px-2 py-1 mt-1">
                                        {literals.monthlyPermit}
                                    </a>
                                </li>
                                <li>
                                    <a href="/about-us" class="">
                                        {literals.about}
                                    </a>
                                </li> 
                                <li>
                                    <a href="/services" class="">
                                        {literals.services}
                                    </a>
                                </li> 
                            </ul>
                            <ul class="nav navbar-nav navbar-right">
                                <NavDropdown title={displayLang}
                                    id="collasible-nav-dropdown" 
                                    show={showLang}
                                    className="text-blue"
                                    onMouseEnter={()=>setShowLang(true)} 
                                    onMouseLeave={()=>setShowLang(false)}
                                    >
                                        <NavDropdown.Item onClick={(e)=>{props.selectedLanguage('en')}}>en </NavDropdown.Item>
                                        <NavDropdown.Item onClick={(e)=>{props.selectedLanguage('fr')}}>fr </NavDropdown.Item>
                                </NavDropdown>                                
                            </ul>
                        </div>                        
                    </div>
                
            </div>
            <div className="main-container header2 d-none d-lg-block d-xl-block">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-4">
                        <a href="/"><img width='80%' src={Logo}/></a>
                    </div>
                    <div className="col-md-8 header-links text-uppercase">
                        <Navbar bg="transparent" variant="light" expand="lg">
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav.Link className=" ml-auto justify-content-end btn rounded-pill btn-red btn-primary px-4 py-2" href="https://processsystech.azurewebsites.net/Account/Register?language=fr&id=4">
                                    {literals.newRates}
                                </Nav.Link>
                                <Nav.Link className=" ml-2 justify-content-end btn rounded-pill btn-primary px-4 py-2" href="https://processsystech.azurewebsites.net/Account/Login?id=4">
                                    {literals.monthlyPermit}
                                </Nav.Link>
                                <Nav.Link className="navlinks justify-content-end" href="/">{literals.home}</Nav.Link>
                                <NavDropdown title={literals.about} alignRight
                                    id="collasible-nav-dropdown" 
                                    show={show}
                                    onMouseEnter={()=>setShow(true)} 
                                    onMouseLeave={()=>setShow(false)}
                                >
                                    <NavDropdown.Item href="/about-us">
                                        {literals.companyProfile}
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="/services">
                                        {literals.services}
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title={displayLang} alignRight
                                    id="collasible-nav-dropdown"
                                    className="text-blue"
                                    show={showLang}
                                    onMouseEnter={()=>setShowLang(true)} 
                                    onMouseLeave={()=>setShowLang(false)}
                                    style={{color: '#121b4d !important'}}
                                    >
                                        <NavDropdown.Item onClick={()=>{props.selectedLanguage('en')}}>en </NavDropdown.Item>
                                        <NavDropdown.Item onClick={()=>{props.selectedLanguage('fr')}}>fr </NavDropdown.Item>
                                </NavDropdown> 
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                </div>
            </div>
        </>
        </>
    )
}
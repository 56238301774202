import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    },
  }),
);

export default function Alerts(prop) {
  const classes = useStyles();
  
  ///---Material Alerts for error, warning, information, success--///

  return (
    <div className={classes.root}>
      {prop.error && <Alert severity="error">{prop.msg}</Alert>}
      {prop.warning && <Alert severity="warning">{prop.msg}</Alert>}
      {prop.info && <Alert severity="info">{prop.msg}</Alert>}
      {prop.success && <Alert severity="success">{prop.msg}</Alert>}
    </div>
  );
}
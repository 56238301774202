import React, { Suspense } from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'font-awesome/css/font-awesome.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/main.css';
import './index.css';
import Routes from './routes';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Suspense fallback={(<div></div>)}>
      {/* <HashRouter>
          <Routes />
      </HashRouter> */}
      <BrowserRouter>
          <Routes />
      </BrowserRouter>
  </Suspense>
  ,document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import ServImg from '../../assets/images/services-img.png';
import Header from "../../views/Common/Header/Header";
import Footer from "../../views/Common/Footer/Footer";
import Img1 from '../../assets/images/img-1.jpg';
import Img2 from '../../assets/images/img-2.jpg';

export default class View extends React.Component{   
    constructor(){        
    super();
    this.state = {             
        literals : {},
        displayLang : '',
        literalsData : []
        }
    }          

    componentDidMount() { 
        let literal = JSON.parse(localStorage.getItem("literals"));                
        let selectLang = localStorage.getItem("displayLang") 
        if(literal !== null){
            this.setState({literals : literal});           
            this.setState({displayLang : selectLang});   
        }
        if(selectLang == null){ 
            selectLang = navigator.language || navigator.userLanguage; 
            selectLang = selectLang.split('-')[0] 
            this.setState({displayLang : selectLang});              
        }
        
    }
    mapLanguages(language, languageData){           
        let languageArray = {};
        if(language == 'fr'){            
            this.setState({displayLang : 'fr'});
            localStorage.setItem("displayLang", 'fr')
        }else if(language == 'en'){            
            this.setState({displayLang : 'en'});
            localStorage.setItem("displayLang", 'en')
        } 
        languageData.forEach((item)=>{                          
            languageArray[item.key] = item[language];            
        })
        localStorage.setItem('literals', JSON.stringify(languageArray))
        return languageArray;
    }

    selectedLanguage(e){         
        this.setState({displayLang : e});
        let result = this.mapLanguages(e,this.state.literalsData);
        this.setState({literals : result});
        window.location.reload();
    }

    render() {
    return(
            <>  
                <Header
                    displayLang = {this.state.displayLang}                    
                    literals = {this.state.literals}
                    
                    selectedLanguage = {(e) => {this.selectedLanguage(e)}}
                />
                <div className="inner-header back-img">                          
                </div>
                <div className="container quiest">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6">
                            <h1 className="text-green" style={{fontSize: "50px"}}>{this.state.literals.services2}</h1>
                            <p>
                                {this.state.literals.bestService}
                            </p>                            
                        </div>
                        <div className="col-md-6">
                            <div className="img-parking">
                             <img width="100%" style={{marginTop: "20px"}} src = {ServImg}/>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div className="services-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="text-uppercase">{this.state.literals.expertIn}</h2>
                                <div className="card-deck">
                                    <div className="card shadow">
                                        <img src={Img1} className="card-img-top" alt="..."/>
                                        <div className="card-body">
                                            <h5 className="card-title">{this.state.literals.garagePark}</h5>
                                            <p className="card-text">{this.state.literals.garageNext}</p>
                                        </div>
                                        <div class="card-footer">
                                            <p>
                                                {this.state.literals.garageDesc1}
                                            </p>
                                            <p>
                                                {this.state.literals.garageDesc2}
                                            </p>
                                            <h4 className="text-green">
                                                {this.state.literals.parkeoDiff}
                                            </h4>
                                            <div className="content-why">                                
                                                <ul>
                                                    <li>{this.state.literals.garageDescLi1}</li>                                                    
                                                    <li>{this.state.literals.garageDescLi2}</li>                                                    
                                                    <li>{this.state.literals.garageDescLi3}</li>                                                    
                                                    <li>{this.state.literals.garageDescLi4}</li>                                                    
                                                    <li>{this.state.literals.garageDescLi5}</li>                                                    
                                                    <li>{this.state.literals.garageDescLi6}</li>                                                    
                                                </ul>
                                            </div>                                            
                                        </div>
                                    </div>                                   
                                </div>
                                <div className="card-deck">                                    
                                    <div className="card shadow">
                                        <img src={Img2} className="card-img-top" alt="..."/>
                                        <div className="card-body">
                                        <h5 className="card-title">{this.state.literals.surfacePark}</h5>
                                        <p className="card-text">{this.state.literals.topLevel}</p>
                                        
                                        </div>
                                        <div class="card-footer">
                                            <p>
                                                {this.state.literals.surfaceDesc1}
                                            </p>
                                            <p>
                                                {this.state.literals.surfaceDesc2}
                                            </p>
                                            <h4 className="text-green">
                                                {this.state.literals.parkeoDiff}
                                            </h4>
                                            <div className="content-why">                                
                                                <ul>
                                                    <li>{this.state.literals.surfaceDescLi1}</li>                                                    
                                                    <li>{this.state.literals.surfaceDescLi2}</li>                                                    
                                                    <li>{this.state.literals.surfaceDescLi3}</li>                                                    
                                                    <li>{this.state.literals.surfaceDescLi4}</li>                                                    
                                                    <li>{this.state.literals.surfaceDescLi5}</li>                                                    
                                                    <li>{this.state.literals.surfaceDescLi6}</li>                                                    
                                                    <li>{this.state.literals.surfaceDescLi7}</li>                                                    
                                                    <li>{this.state.literals.surfaceDescLi8}</li>                                                    
                                                </ul>
                                            </div>                                            
                                        </div>
                                    </div>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              <Footer
                literals = {this.state.literals}
              />                
            </>
        )
    }
}